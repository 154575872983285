import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{outlet}}", {"contents":"{{outlet}}","moduleName":"irene/components/storeknox/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/index.hbs"}});
import Component from '@glimmer/component';

export default class StoreknoxComponent extends Component {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    Storeknox: typeof StoreknoxComponent;
  }
}
