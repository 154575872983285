import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Storeknox::Discover::RequestedApps::Table @queryParams={{@queryParams}} />", {"contents":"<Storeknox::Discover::RequestedApps::Table @queryParams={{@queryParams}} />","moduleName":"irene/components/storeknox/discover/requested-apps/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/discover/requested-apps/index.hbs"}});
import Component from '@glimmer/component';
import { StoreknoxDiscoveryRequestedQueryParam } from 'irene/routes/authenticated/storeknox/discover/requested';

export interface StoreknoxDiscoverRequestedAppsSignature {
  Args: {
    queryParams: StoreknoxDiscoveryRequestedQueryParam;
  };
}

export default class StoreknoxDiscoverRequestedAppsComponent extends Component<StoreknoxDiscoveryRequestedQueryParam> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Discover::RequestedApps': typeof StoreknoxDiscoverRequestedAppsComponent;
  }
}
