import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @width='full'\n  @spacing='1.5'\n  @alignItems='center'\n  local-class='app-header-info-banner {{this.color}}'\n  class='py-1 px-3'\n  ...attributes\n>\n  {{#if (has-block 'icon')}}\n    {{yield to='icon'}}\n  {{/if}}\n\n  {{yield}}\n</AkStack>", {"contents":"<AkStack\n  @width='full'\n  @spacing='1.5'\n  @alignItems='center'\n  local-class='app-header-info-banner {{this.color}}'\n  class='py-1 px-3'\n  ...attributes\n>\n  {{#if (has-block 'icon')}}\n    {{yield to='icon'}}\n  {{/if}}\n\n  {{yield}}\n</AkStack>","moduleName":"irene/components/storeknox/inventory-details/section-header/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/section-header/index.hbs"}});
import Component from '@glimmer/component';

interface StoreknoxInventoryDetailsSectionHeaderSignature {
  Element: HTMLElement;
  Args: {
    color?: 'default' | 'white';
  };
  Blocks: {
    icon?: [];
    default: [];
  };
}

export default class StoreknoxInventoryDetailsSectionHeaderComponent extends Component<StoreknoxInventoryDetailsSectionHeaderSignature> {
  get color() {
    return this.args.color || 'default';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::SectionHeader': typeof StoreknoxInventoryDetailsSectionHeaderComponent;
  }
}
