import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @loading}}\n  <AkStack @justifyContent='center'>\n    <AkSkeleton @width='30px' @height='30px' @variant='circular' />\n  </AkStack>\n{{else}}\n  {{#if @data.isIos}}\n    <AkSvg::AppstoreLogo />\n  {{/if}}\n\n  {{#if @data.isAndroid}}\n    <AkSvg::StoreknoxPlaystoreLogo />\n  {{/if}}\n{{/if}}", {"contents":"{{#if @loading}}\n  <AkStack @justifyContent='center'>\n    <AkSkeleton @width='30px' @height='30px' @variant='circular' />\n  </AkStack>\n{{else}}\n  {{#if @data.isIos}}\n    <AkSvg::AppstoreLogo />\n  {{/if}}\n\n  {{#if @data.isAndroid}}\n    <AkSvg::StoreknoxPlaystoreLogo />\n  {{/if}}\n{{/if}}","moduleName":"irene/components/storeknox/table-columns/store/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/table-columns/store/index.hbs"}});
import Component from '@glimmer/component';
import { StoreknoxCommonTableColumnsData } from '..';

export interface StoreknoxDiscoverTableColumnsStoreSignature {
  Element: HTMLElement;
  Args: {
    data: StoreknoxCommonTableColumnsData;
    loading?: boolean;
  };
}

export default class StoreknoxDiscoverTableColumnsStoreComponent extends Component<StoreknoxDiscoverTableColumnsStoreSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Discover::TableColumns::Store': typeof StoreknoxDiscoverTableColumnsStoreComponent;
  }
}
