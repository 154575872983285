import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{!-- <AkStack local-class='app-list-header'>\n  <div local-class='search-input-container-width'>\n    <AkTextField @placeholder={{t 'search'}} @value={{this.searchQuery}}>\n      <:rightAdornment>\n        {{#if this.searchQuery}}\n          <AkStack\n            @alignItems='center'\n            local-class='close-search-text'\n            {{on 'click' this.clearSearch}}\n          >\n            <AkIcon @iconName='close' />\n          </AkStack>\n        {{else}}\n          <AkIcon @iconName='search' @color='textSecondary' />\n        {{/if}}\n      </:rightAdornment>\n    </AkTextField>\n  </div>\n</AkStack> --}}\n\n<div local-class='app-list-table'>\n  <Storeknox::Inventory::AppList::Table />\n</div>", {"contents":"{{!-- <AkStack local-class='app-list-header'>\n  <div local-class='search-input-container-width'>\n    <AkTextField @placeholder={{t 'search'}} @value={{this.searchQuery}}>\n      <:rightAdornment>\n        {{#if this.searchQuery}}\n          <AkStack\n            @alignItems='center'\n            local-class='close-search-text'\n            {{on 'click' this.clearSearch}}\n          >\n            <AkIcon @iconName='close' />\n          </AkStack>\n        {{else}}\n          <AkIcon @iconName='search' @color='textSecondary' />\n        {{/if}}\n      </:rightAdornment>\n    </AkTextField>\n  </div>\n</AkStack> --}}\n\n<div local-class='app-list-table'>\n  <Storeknox::Inventory::AppList::Table />\n</div>","moduleName":"irene/components/storeknox/inventory/app-list/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory/app-list/index.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class StoreknoxInventoryAppListComponent extends Component {
  @tracked searchQuery = '';
  @tracked discoverClicked = false;

  @action
  discoverApp() {
    this.searchQuery = 'Shell Test';
    this.discoverClicked = true;
  }

  @action
  clearSearch() {
    this.searchQuery = '';
    this.discoverClicked = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Inventory::AppList': typeof StoreknoxInventoryAppListComponent;
  }
}
