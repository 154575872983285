import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @loading}}\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkSkeleton @width='180px' @height='16px' />\n\n    <AkSkeleton @width='115px' @height='16px' />\n  </AkStack>\n{{else}}\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography\n      @color='secondary'\n      @noWrap={{true}}\n      title={{@data.devName}}\n      {{style width='100%'}}\n    >\n      {{@data.devName}}\n    </AkTypography>\n\n    {{#if @data.devEmail}}\n      <AkTypography @color='neutral' @noWrap={{true}} title={{@data.devEmail}}>\n        {{@data.devEmail}}\n      </AkTypography>\n    {{else}}\n      <AkTypography @color='neutral' @noWrap={{true}} class='pr-4'>\n        {{t 'noDataAvailable'}}\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n{{/if}}", {"contents":"{{#if @loading}}\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkSkeleton @width='180px' @height='16px' />\n\n    <AkSkeleton @width='115px' @height='16px' />\n  </AkStack>\n{{else}}\n  <AkStack @direction='column' @spacing='0.5'>\n    <AkTypography\n      @color='secondary'\n      @noWrap={{true}}\n      title={{@data.devName}}\n      {{style width='100%'}}\n    >\n      {{@data.devName}}\n    </AkTypography>\n\n    {{#if @data.devEmail}}\n      <AkTypography @color='neutral' @noWrap={{true}} title={{@data.devEmail}}>\n        {{@data.devEmail}}\n      </AkTypography>\n    {{else}}\n      <AkTypography @color='neutral' @noWrap={{true}} class='pr-4'>\n        {{t 'noDataAvailable'}}\n      </AkTypography>\n    {{/if}}\n  </AkStack>\n{{/if}}","moduleName":"irene/components/storeknox/table-columns/developer/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/table-columns/developer/index.hbs"}});
import Component from '@glimmer/component';
import { StoreknoxCommonTableColumnsData } from '..';

export interface StoreknoxDiscoverTableColumnsDeveloperSignature {
  Element: HTMLElement;
  Args: {
    data: StoreknoxCommonTableColumnsData;
    loading?: boolean;
  };
}

export default class StoreknoxDiscoverTableColumnsDeveloperComponent extends Component<StoreknoxDiscoverTableColumnsDeveloperSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::Discover::TableColumns::Developer': typeof StoreknoxDiscoverTableColumnsDeveloperComponent;
  }
}
