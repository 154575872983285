import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div local-class='page-wrapper-root'>\n  <div local-class='page-wrapper-container'>\n    {{yield}}\n  </div>\n</div>", {"contents":"<div local-class='page-wrapper-root'>\n  <div local-class='page-wrapper-container'>\n    {{yield}}\n  </div>\n</div>","moduleName":"irene/components/page-wrapper/index.hbs","parseOptions":{"srcName":"irene/components/page-wrapper/index.hbs"}});
import Component from '@glimmer/component';

interface PageWrapperSignature {
  Blocks: {
    default: [];
  };
}

export default class PageWrapperComponent extends Component<PageWrapperSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    PageWrapper: typeof PageWrapperComponent;
  }
}
