import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkStack\n  @width={{@width}}\n  @justifyContent={{@justifyContent}}\n  @alignItems={{@alignItems}}\n  @direction={{@direction}}\n  @spacing={{@spacing}}\n  local-class='app-details-info-container'\n  class='p-3'\n  ...attributes\n>\n  {{yield}}\n</AkStack>", {"contents":"<AkStack\n  @width={{@width}}\n  @justifyContent={{@justifyContent}}\n  @alignItems={{@alignItems}}\n  @direction={{@direction}}\n  @spacing={{@spacing}}\n  local-class='app-details-info-container'\n  class='p-3'\n  ...attributes\n>\n  {{yield}}\n</AkStack>","moduleName":"irene/components/storeknox/inventory-details/section-info/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/section-info/index.hbs"}});
import Component from '@glimmer/component';
import { AkStackArgs } from 'irene/components/ak-stack';

interface StoreknoxInventoryDetailsSectionInfoSignature {
  Element: HTMLElement;
  Args: AkStackArgs;
  Blocks: {
    default: [];
  };
}

export default class StoreknoxInventoryDetailsSectionInfoComponent extends Component<StoreknoxInventoryDetailsSectionInfoSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::SectionInfo': typeof StoreknoxInventoryDetailsSectionInfoComponent;
  }
}
