import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<AkButton\n  @variant='outlined'\n  @disabled={{@disabled}}\n  @color={{if @needsAction 'primary'}}\n  local-class='action-button-root {{if @needsAction \"needs-action\"}}'\n  ...attributes\n>\n  <:leftIcon>\n    <AkIcon @iconName='report' @color='inherit' />\n  </:leftIcon>\n\n  <:default>\n    {{@label}}\n  </:default>\n\n  <:rightIcon>\n    {{#unless @hideRightIcon}}\n      <AkIcon\n        @iconName='arrow-outward'\n        @color='textPrimary'\n        {{style fontSize='18px !important'}}\n      />\n    {{/unless}}\n  </:rightIcon>\n</AkButton>", {"contents":"<AkButton\n  @variant='outlined'\n  @disabled={{@disabled}}\n  @color={{if @needsAction 'primary'}}\n  local-class='action-button-root {{if @needsAction \"needs-action\"}}'\n  ...attributes\n>\n  <:leftIcon>\n    <AkIcon @iconName='report' @color='inherit' />\n  </:leftIcon>\n\n  <:default>\n    {{@label}}\n  </:default>\n\n  <:rightIcon>\n    {{#unless @hideRightIcon}}\n      <AkIcon\n        @iconName='arrow-outward'\n        @color='textPrimary'\n        {{style fontSize='18px !important'}}\n      />\n    {{/unless}}\n  </:rightIcon>\n</AkButton>","moduleName":"irene/components/storeknox/inventory-details/app-details/actions-list/button/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory-details/app-details/actions-list/button/index.hbs"}});
import Component from '@glimmer/component';
import { ButtonTags } from 'irene/components/ak-button';

interface StoreknoxInventoryDetailsAppDetailsActionsListButtonSignature {
  Element: HTMLElementTagNameMap[ButtonTags];
  Args: {
    needsAction: boolean;
    disabled?: boolean;
    label: string;
    hideRightIcon?: boolean;
  };
}

export default class StoreknoxInventoryDetailsAppDetailsActionsListButtonComponent extends Component<StoreknoxInventoryDetailsAppDetailsActionsListButtonSignature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Storeknox::InventoryDetails::AppDetails::ActionsList::Button': typeof StoreknoxInventoryDetailsAppDetailsActionsListButtonComponent;
  }
}
