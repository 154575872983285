import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @loading}}\n  <AkStack @justifyContent='center'>\n    <AkSkeleton @width='20px' @height='20px' @variant='circular' />\n  </AkStack>\n{{else}}\n  <AkCheckbox\n    @disabled={{@disabledChecking}}\n    @checked={{this.isChecked}}\n    @onClick={{this.handleChange}}\n  />\n{{/if}}", {"contents":"{{#if @loading}}\n  <AkStack @justifyContent='center'>\n    <AkSkeleton @width='20px' @height='20px' @variant='circular' />\n  </AkStack>\n{{else}}\n  <AkCheckbox\n    @disabled={{@disabledChecking}}\n    @checked={{this.isChecked}}\n    @onClick={{this.handleChange}}\n  />\n{{/if}}","moduleName":"irene/components/storeknox/inventory/app-list/table/checkbox/index.hbs","parseOptions":{"srcName":"irene/components/storeknox/inventory/app-list/table/checkbox/index.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import SkAppModel from 'irene/models/sk-app';

interface StoreknoxInventoryAppListTableMonitoringCheckboxSignature {
  Args: {
    app?: SkAppModel;
    loading: boolean;
    disabledChecking: boolean;
    appIsSelected: boolean;
    selectedDisabledAppIds: string[];
    selectDisabledAppRow: (ulid: string, value: boolean) => void;
  };
}

export default class StoreknoxInventoryAppListTableMonitoringCheckboxComponent extends Component<StoreknoxInventoryAppListTableMonitoringCheckboxSignature> {
  get docUlid() {
    return this.args.app?.appMetadata?.doc_ulid as string;
  }

  get isChecked() {
    return Boolean(
      this.args.selectedDisabledAppIds.find((id) => id === this.docUlid)
    );
  }

  @action handleChange(event: Event) {
    this.args.selectDisabledAppRow(
      this.docUlid,
      (event.target as HTMLInputElement).checked
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'storeknox/inventory/app-list/table/checkbox': typeof StoreknoxInventoryAppListTableMonitoringCheckboxComponent;
  }
}
